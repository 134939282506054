module.exports = [
  '/',
  '/contact',
  '/experience',
  '/hello',
  '/projects',
  '/recommendations',
  '/skills',
  '/influences',
  '/reads',
  '/tools'
];
