export default [
  {
    id: "abraao",
    companyImage: "logo-banco-inter.png",
    companyName: "Banco Inter",
    companyUrl: "https://www.bancointer.com.br/en/",
    formerCompanyName: "Conta Azul",
    formerCompanyUrl: "https://contaazul.com",
    personImage: "avatar-abraao.jpg",
    personName: "Daniel Abraão",
    personPosition: "Engineering Manager",
    personUrl: "https://br.linkedin.com/in/daniel-abraao",
    content: "For about one year, I had the pleasure of having him on my team. His dedication and technical expertise were crucial for the success of the project: to build a Design System team to increase productivity and UX consistency. Beyond his technical skills, he has a level of communication that I hadn’t ever seen, elaborates constructive questions, and is very creative. I strongly recommend him and hope to work with him again."
  },
  {
    id: "branco",
    companyImage: "logo-conta-azul.jpg",
    companyName: "Conta Azul",
    companyUrl: "https://contaazul.com/",
    personImage: "avatar-branco.jpeg",
    personName: "Guilherme Branco",
    personUrl: "https://linkedin.com/in/linkguicarvalho",
    personPosition: "Programmer",
    content: "Camargo helped me to understand and to apply great principles. Writing simple code, decreasing responsibility. Calibrating solutions’ complexity according to the problems’ size. Reusing code and the importance of practicing Red-Green-Blue (TDD). I always saw him outputting high-quality deliverables."
  },
  {
    id: "bruckheimer",
    companyImage: "logo-runtastic.jpeg",
    companyName: "Runtastic",
    companyUrl: "https://www.runtastic.com/",
    formerCompanyName: "JobScore",
    formerCompanyUrl: "https://www.jobscore.com",
    personImage: "avatar-bruckheimer.jpeg",
    personName: "Alessandro Brückheimer",
    personUrl: "https://www.linkedin.com/in/alebruck/",
    personPosition: "Programmer",
    content: "I worked with Rafael at JobScore for a few months. In that short period, he made a good impact in the engineering department, from introducing new front-end practices to a whole new approach to reuse UI code. I recommend Rafael to teams looking for professionals well versed with software fundamentals, long experience, and high delivery capacity."
  },
  {
    id: "chapuis",
    companyImage: "logo-critical-techworks.png",
    companyName: "Critical TechWorks",
    companyUrl: "https://www.criticaltechworks.com/",
    formerCompanyName: "Conta Azul",
    formerCompanyUrl: "https://contaazul.com/",
    personImage: "avatar-chapuis.jpeg",
    personName: "Lorival Chapuis",
    personUrl: "https://www.linkedin.com/in/lorival/",
    personPosition: "Programmer",
    content: "Camargo is an out of the box professional. Experienced and self-manageable, he is one of the best professionals who I had the opportunity to work with. Sees beyond the problem and always starts with the simplest solution. I highly recommend him."
  },
  {
    id: "cunha",
    companyImage: "logo-jobscore.png",
    companyName: "JobScore",
    companyUrl: "https://www.jobscore.com",
    personImage: "avatar-cunha.jpg",
    personName: "Lucas Cunha",
    personUrl: "https://www.linkedin.com/in/lucas-felipe-da-cunha-06628969/",
    personPosition: "Programmer",
    content: "Rafael was responsible for introducing a new approach to how we organized JavaScript code at JobScore. He is one of the best engineers I had the privilege to work with. I think his best trait is making sure that everything is reusable, easy to understand, and maintainable."
  },
  {
    id: "jim",
    companyImage: "logo-ebanx.svg",
    companyName: "Ebanx",
    companyUrl: "https://business.ebanx.com/en/",
    formerCompanyName: "Conta Azul",
    formerCompanyUrl: "https://contaazul.com",
    personImage: "avatar-jim.jpg",
    personName: "Jim Chien",
    personUrl: "https://www.linkedin.com/in/jim-chien/",
    personPosition: "Programmer",
    content: "Be part of the Design System team with Camargo was an outstanding experience both personally and professionally. In a short period, he saved tons of programming time with his ability to abstract components. Definitely being close to him made me think more clearly about Clean Code, reusability, and TDD. If you need a star in your team, better call Camargo!"
  },
  {
    id: "matias",
    companyImage: "logo-nubank.png",
    companyName: "Nubank",
    companyUrl: "https://nubank.com.br/en/",
    formerCompanyName: "Conta Azul",
    formerCompanyUrl: "https://contaazul.com",
    personImage: "avatar-matias.jpeg",
    personName: "Tiago Matias",
    personPosition: "Programmer",
    personUrl: "https://www.linkedin.com/in/t-matias/",
    content: "Rafael is one of the most creative and productive people I know. Also, the technical quality of what he builds is enviable. Always brings something new to his side projects. He’s a person that practices and reflects a lot about his profession. The results are evident."
  },
  {
    id: "rodrigues",
    companyImage: "logo-quinto-andar.png",
    companyName: "Quinto Andar",
    companyUrl: "https://www.quintoandar.com.br/",
    formerCompanyName: "Conta Azul",
    formerCompanyUrl: "https://contaazul.com",
    personImage: "avatar-rodrigues.jpeg",
    personName: "Fernando Rodrigues",
    personPosition: "Programmer",
    personUrl: "https://www.linkedin.com/in/fernahh/",
    content: "I had the pleasure of working with Rafael on several web projects. He was such an awesome and inspiring developer for me, creating simple and elegant solutions. He gave me one of the best tips for my career. Always deliver what nobody expects. I’d be happy to work with him again in the future if the opportunity presented itself!"
  }
];
